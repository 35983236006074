<template>
	<header id="header" class="no-gutters py-0 py-md-1">
		<div class="row pt-3 pt-lg-0 mx-0 px-2">
			<div class="col-1 d-none d-lg-inline text-end">
				<router-link to="/"
					><img
						itemprop="logo"
						src="@/assets/img/logo.webp"
						alt="Logo"
						class="logo me-2 d-none d-lg-inline"
				/></router-link>
			</div>
			<div class="col-7 col-lg-5 py-lg-1 mx-lg-3 px-1">
				<router-link to="/">
					<h1>Trent Law Firm, PLLC</h1>
				</router-link>
				<div class="mt-lg-0 contact">
					<a href="tel:+1-319-277-1610">
						<span class="me-2 pt-2 pr-2 pb-0">
							<img
								src="@/assets/img/phone.webp"
								alt="Phone"
								class="icon"
							/>
							319-277-1610
						</span>
					</a>
					<span class="d-inline d-lg-none"
						><hr class="py-1 m-0"
					/></span>
					<a :href="'mailto:' + email.info + '@' + email.domain">
						<span class="py-2">
							<img
								src="@/assets/img/email.webp"
								alt="Email"
								class="icon d-none d-sm-inline"
							/>
							{{ email.info }}@{{ email.domain }}
						</span>
					</a>
					<!-- <span>
						<a target="_blank" href="https://goo.gl/maps/hy17UnPrFjr">
							<img src="@/assets/img/google-maps.webp" alt="Map" class="icon" />
							3429 Midway Dr, Cedar Falls, IA 50613
						</a>
					</span> -->
				</div>
			</div>
			<div
				class="col me-3 mt-lg-0 py-lg-1 text-end d-none d-lg-inline text-end"
			>
				<router-link to="/"
					><button class="btn btn-info btn-lg mx-1">
						Home
					</button></router-link
				>
				<router-link to="/about"
					><button class="btn btn-info btn-lg mx-1">
						Our Team
					</button></router-link
				>
				<router-link to="/services"
					><button class="btn btn-info btn-lg mx-1">
						Our Services
					</button></router-link
				>
				<router-link to="/careers"
					><button class="btn btn-info btn-lg mx-1">
						Careers
					</button></router-link
				>
				<router-link to="/contact"
					><button class="btn btn-info btn-lg mx-1">
						Contact
					</button></router-link
				>
			</div>
			<div
				class="col d-lg-none d-inline mb-1 px-0 text-center"
				role="group"
			>
				<div class="mb-2 text-center">
					<router-link to="/"
						><img
							src="@/assets/img/logo.webp"
							alt="Logo"
							class="mobile-logo"
					/></router-link>
				</div>
				<div class="dropdown me-1">
					<button
						class="btn btn-info btn-lg dropdown-toggle"
						@click="dropdown('main-menu')"
					>
						Menu
					</button>
					<div id="main-menu" class="dropdown-menu dropdown-menu-end">
						<router-link to="/"
							><a
								class="dropdown-item"
								href="#"
								@click="dropdown('main-menu')"
								>Home</a
							></router-link
						>
						<router-link to="/about"
							><a
								class="dropdown-item"
								href="#"
								@click="dropdown('main-menu')"
								>Our Team</a
							></router-link
						>
						<router-link to="/services"
							><a
								class="dropdown-item"
								href="#"
								@click="dropdown('main-menu')"
								>Our Services</a
							></router-link
						>
						<router-link to="/careers"
							><a
								class="dropdown-item"
								href="#"
								@click="dropdown('main-menu')"
								>Careers</a
							></router-link
						>
						<router-link to="/contact"
							><a
								class="dropdown-item"
								href="#"
								@click="dropdown('main-menu')"
								>Contact</a
							></router-link
						>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
export default {
	props: ["email"],
	methods: {
		dropdown(target) {
			document.getElementById(target).classList.toggle("show");
		},
	},
};
</script>
