import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/Home.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "./components/About.vue"),
		props: true,
		meta: {
			title: "Trent Law Firm | About",
		},
	},
	{
		path: "/services",
		name: "services",
		component: () => import("./components/Services.vue"),
		props: true,
		meta: {
			title: "Trent Law Firm | Services",
		},
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("./components/Contact.vue"),
		props: true,
		meta: {
			title: "Trent Law Firm | Contact",
		},
	},
	{
		path: "/careers",
		name: "promotion",
		component: () => import("./components/Promotion.vue"),
		props: true,
		meta: {
			title: "Trent Law Firm | Careers",
		},
	},
	{
		path: "/privacy",
		name: "privacy",
		component: () => import("./components/Privacy.vue"),
		props: true,
		meta: {
			title: "Trent Law Firm | Privacy",
		},
	},
	{
		path: "/zoomWithBrooke",
		name: "zoomWithBrooke",
		component: () => import("./components/ZoomBrooke.vue"),
	},
	{
		path: "/zoomWithVirginia",
		name: "zoomWithVirginia",
		component: () => import("./components/ZoomVirginia.vue"),
	},
	{
		path: "/zoomWithRebecca",
		name: "zoomWithRebecca",
		component: () => import("./components/ZoomRebecca.vue"),
	},
	// { path: "/:pathMatch(.*)*", name: "not-found", component: Home },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
