<template>
	<footer id="footer" class="container-fluid px-0">
		<div class="row px-2 mx-0">
			<div class="col-12 col-lg-6">
				<small
					>&copy;&nbsp;Trent Law Firm, PLLC&nbsp;&mdash;&nbsp;
					<router-link to="/privacy" id="privacy-link"
						>Privacy Agreement</router-link
					>
				</small>
			</div>
			<div class="col-12 col-lg-6 text-lg-end">
				<small id="dev-credit"
					>Designed and created by Jubal Slone</small
				>
			</div>
		</div>
	</footer>
</template>
