<template>
	<main>
		<Header :email="email"></Header>

		<router-view :email="email" v-slot="{ Component }">
			<transition>
				<component :is="Component" />
			</transition>
		</router-view>

		<Footer></Footer>
	</main>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
	name: "app",
	components: {
		Header,
		Footer,
	},
	watch: {
		$route(to, from) {
			document.title =
				to.meta.title ||
				"Trent Law Firm | Cedar Falls IA | Real Estate | Creditor's Rights | Estate Planning";
		},
	},
	created() {
		document.title =
			this.$route.meta.title ||
			"Trent Law Firm | Cedar Falls IA | Real Estate | Creditor's Rights | Estate Planning";
	},
	data() {
		return {
			email: {
				brooke: "Brooke.Trent",
				emily: "Emily.Sheerer",
				rebecca: "Rebecca.Feiereisen",
				virginia: "Virginia.Wilber",
				laura: "Laura.Range",
				megan: "Megan.Johnson",
				terry: "Terry.Osmundson",
				barb: "Barb.Johnson",
				brittany: "Brittany.Frerlis",
				suzanne: "Suzanne.Wurzer",
				info: "Info",
				email: "Email",
				applications: "Applications",
				domain: "TrentLawIowa.com",
			},
		};
	},
};
</script>

<style></style>
