<template>
	<div id="home" class="container-xl my-0 mt-xl-3">
		<div class="row">
			<!--<div class="col-12">
				<div class="alert covid-alert mt-3">
					<p class="my-1"><strong>Notice:</strong> All in-person appointments (or document drop-off arrangements) must be made in advance. Please contact us for details.</p> 
				</div>
			</div>-->
			<div class="col-12 text-center cover-photo-wrapper mt-3">
				<img
					src="@/assets/img/cover.webp"
					class="cover-photo img-fluid mt-2 d-none d-xl-block w-100"
					alt="Trent Law Firm Building"
				/>
				<img
					src="@/assets/img/cover.webp"
					class="img-fluid d-xl-none d-block"
					alt="Trent Law Firm Building"
				/>
			</div>
			<div class="col-12">
				<div class="mt-3 px-2">
					<h2 class="my-2 text-center">Practical. Responsive. Advocacy.</h2>
					<p>
						Trent Law Firm, PLLC is a general practice law firm that seeks to
						provide client-focused, results-driven legal services to
						individuals, families, and businesses across Iowa. Although the firm
						is general practice, the most common practice areas include estate
						planning (including wills, trusts, and probate), elder law
						(including guardianships, conservatorships, and Miller Trusts),
						family law, business law (including creditors' rights such as
						collections, and mechanics' liens), real estate, and general
						litigation. Although our office is located in the Cedar Valley (in
						the Cedar Falls/Waterloo area), our attorneys provide services
						throughout Iowa; this means that our attorneys routinely conduct
						client meetings and attend hearings in Cedar Rapids, Iowa City,
						Waverly, and surrounding areas. Our attorneys and legal assistants
						are here to assist your legal needs.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["email"],
};
</script>
